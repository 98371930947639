import * as React from "react"
// import { graphql } from 'gatsby'
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import { css } from "@emotion/react"

const Row = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  `

const HomeSplash = styled.div`
  display: flex;
  max-height: 850px;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  & > * {
  flex-grow: 1;
}
  `

const GMaps = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.9817666212566!2d-122.5950057838687!3d49.21987288313937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d35e602640d9%3A0xf255fa5c72a8945d!2sOuter%20Limits%20Clothing%20Co!5e0!3m2!1sen!2sca!4v1626291290136!5m2!1sen!2sca" // google maps embed

const GStyle = {
	border: `0`,
}

const URL = "https://form.jotform.com/203238281863255" //Jotform embed URL


const IStyle = {
	width: `100%`,
	height: `640px`,
	overflow: `hidden`, // hide scrolls
	scrolling: `no`,
	border: `none`,
	margin: `0 16px 3em 16px`,

}

const Contact = () => (
	<Layout>
		<SEO title="Contact Outer Limits Clothing" />
		<HomeSplash>
			<iframe title="Contact Outer Limits Clothing" src={GMaps} width="600" height="450" frameborder="0" style={GStyle} allowfullscreen="" aria-hidden="false" disable="geolocation; microphone; camera"></iframe>
		</HomeSplash>

		<Row style={{ marginTop: `3em`, }}>
			<h1 style={{ textAlign: `center` }}>Outer Limits<br />Clothing</h1>
			<p className="textCenter">Please fill out the form below to contact me.<br />Alternatively, call us at <a href="tel:+16044671166" title="Outer Limits Clothing Contact Number">(604) 467-1166</a></p>
		</Row>

		{/* main text */}
		<Row>
			<iframe title="contact form" style={IStyle} src={URL} scrolling="no" allowfullscreen="true" allowtransparency="true" onload="window.parent.scrollTo(0,0)"></iframe>
		</Row>

		<Row
			style={{ marginBottom: `4em`, }} >
			<h3 style={{ textAlign: `center` }}>Outer Limits Clothing</h3>
			<p className="textCenter">Unit 100 - 22716 Dewdney Trunk Rd<br />Maple Ridge,<br />BC V2X 3K2</p>
			<p className="textCenter">(604) 467-1166</p>
		</Row>


	</Layout>
)

export default Contact